import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SaveOutlined } from '@ant-design/icons';
import FolderService from '../../services/folder.service';
import UserService from '../../services/user.service';
import { notificationSuccess, requestError } from '../../helpers/notification';
import { selectModeSelected, selectPlanningSelected } from '../../redux/slices/app/planning.slice';
import {
    selectSelectedFolder,
    selectUpdatedFolderBroadcast,
    setSelectedFolder as setFolderSelected,
} from '../../redux/slices/app/folder.slice';
import { generateString } from '../../helpers/string-helper';
import FolderExplorerModal from '../explorer/FolderExplorerModal';
import { selectCurrentUser, updateUserPreferences } from '../../redux/slices/app/user.slice';
import { getPlanningAccess, USER_ACCESS } from '../../helpers/planning';

const EditPlanningModal = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [isVisible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const planningSelected = useSelector(selectPlanningSelected);
    const updatedFolderBroadcast = useSelector(selectUpdatedFolderBroadcast);
    const folderSelected = useSelector(selectSelectedFolder);
    const currentUser = useSelector(selectCurrentUser);
    const planningAccess = getPlanningAccess(planningSelected, currentUser);
    const canEditPlanningInfo = planningAccess === USER_ACCESS.OWNER || currentUser.isAdmin;
    const isSandbox = useSelector(selectModeSelected) === 'sandbox';
    const [form] = Form.useForm();
    const [selectedFolder, setSelectedFolder] = useState(isSandbox ? null : folderSelected);
    const folderExplorerRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isVisible) return;

        if (!isSandbox) {
            setLoading(true);
            UserService.listUsers()
                .then((users) => {
                    const usersList = users.map((user) => ({
                        label: `${user.firstName} ${user.lastName}`,
                        value: user.userId,
                    }));
                    setUserOptions(usersList);
                    setLoading(false);
                })
                .catch((error) => {
                    requestError(error, t('top_menu.edit_planning'));
                    setLoading(false);
                });
        }
    }, [isVisible]);

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (planningSelected) {
            form.setFieldsValue({
                name: planningSelected.name,
                ownerId: planningSelected.ownerId,
            });
        }
    }, [planningSelected]);

    useEffect(() => {
        if (updatedFolderBroadcast && planningSelected.id === +updatedFolderBroadcast.id) {
            form.setFieldsValue(updatedFolderBroadcast.updatedData);
        }
    }, [updatedFolderBroadcast]);

    const onSaveOwner = () => {
        const ownerId = form.getFieldValue('ownerId');
        if (ownerId && ownerId !== planningSelected.ownerId) {
            Modal.confirm({
                title: t('top_menu.edit_planning'),
                content: t('general.modification_confirm'),
                onOk() {
                    FolderService.changeOwner(planningSelected.id, ownerId)
                        .then(() => {
                            notificationSuccess(t('top_menu.edit_planning'), t('general.saved_success'));
                        })
                        .catch((error) => {
                            requestError(error, t('top_menu.edit_planning'));
                        });
                },
            });
        }
    };

    const onSaveName = () => {
        const name = form.getFieldValue('name');
        if (name && name !== planningSelected.name) {
            FolderService.renamePlanning(planningSelected.id, name, planningSelected.ownerId)
                .then(() => {
                    notificationSuccess(t('top_menu.edit_planning'), t('general.saved_success'));
                })
                .catch((error) => {
                    requestError(error, t('top_menu.edit_planning'));
                });
        }
    };

    const handleOpenFolderExplorer = () => {
        if (folderExplorerRef.current) {
            folderExplorerRef.current.openModal();
        }
    };

    const handleFolderChosen = (folder) => {
        setSelectedFolder(folder);
        form.setFieldsValue({ folderId: folder.id });
    };

    const onSaveFolder = () => {
        const folderId = form.getFieldValue('folderId');
        if (folderId && folderId !== planningSelected.folderId) {
            Modal.confirm({
                title: t('top_menu.edit_planning'),
                content: isSandbox
                    ? t('edit_planning_modal.sandbox_folder_confirmation')
                    : t('general.modification_confirm'),
                onOk() {
                    (isSandbox
                        ? UserService.moveSandboxPlanning(planningSelected.id, folderId)
                        : FolderService.movePlanning(planningSelected.id, folderId)
                    )
                        .then(() => {
                            notificationSuccess(t('top_menu.edit_planning'), t('general.saved_success'));
                            dispatch(setFolderSelected(selectedFolder));
                            dispatch(updateUserPreferences({ open_folder: selectedFolder }));
                        })
                        .catch((error) => {
                            requestError(error, t('top_menu.move_planning'));
                        });
                },
            });
        }
    };

    return (
        <Modal
            title={t('top_menu.edit_planning')}
            className="add-planning-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
            maskClosable={false}
        >
            <Spin spinning={loading}>
                <Form form={form} disabled={!canEditPlanningInfo} layout="vertical">
                    <div className="flex items-end gap-2 mb-4">
                        <Form.Item name="folderId" label={t('planning_tabs.folder')} className="flex-1 mb-0">
                            <Button type="primary" onClick={handleOpenFolderExplorer} className="w-full text-left">
                                {selectedFolder?.name || t('general.select_folder')}
                            </Button>
                        </Form.Item>
                        <Button icon={<SaveOutlined />} onClick={onSaveFolder} />
                    </div>
                    {!isSandbox && (
                        <div className="flex items-end gap-2 mb-4">
                            <Form.Item
                                name="ownerId"
                                label={t('general.owner')}
                                rules={[{ required: true, message: t('general.required_field') }]}
                                className="flex-1 mb-0"
                            >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {userOptions.map((user) => (
                                        <Select.Option key={generateString(5)} value={user.value}>
                                            {user.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Button icon={<SaveOutlined />} onClick={onSaveOwner} />
                        </div>
                    )}
                    <div className="flex items-end gap-2 mb-4">
                        <Form.Item
                            name="name"
                            label={t('general.name')}
                            rules={[{ required: true, message: t('general.required_field') }]}
                            className="flex-1 mb-0"
                        >
                            <Input type="text" />
                        </Form.Item>
                        <Button icon={<SaveOutlined />} onClick={onSaveName} />
                    </div>
                </Form>
            </Spin>

            <FolderExplorerModal
                ref={folderExplorerRef}
                onFolderChosen={handleFolderChosen}
                currentFolder={selectedFolder}
            />
        </Modal>
    );
});

export default EditPlanningModal;
